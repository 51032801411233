<template>
  <div id="app">
    <div class="app_container">
    <header class="hat">
        <router-link to="/"><div class="logotype_container"> <img id="logotype" src="@/assets/mainlogo.svg" alt=""> </div> </router-link>
        <div v-if="currentRouteName === 'Myfirst'" class="burger" v-bind:class="{'burgerToggle': burgerToggle}" v-on:click="changeBtn"><span></span></div>
      <div class="nav" v-bind:class="{'nav_active': navActive}">
      <router-link to="/">Главная</router-link>
        <router-link to="/Appeals">Обращения</router-link>
        <router-link to="/Payment">Оплата и показания</router-link>
        <div class="emergency">
        <p class="emergency_service_info">Аварийная служба:</p>
        <a href="tel:+74732602316" id="tel" class="tel">260-23-16</a>
        </div>
    </div>

    </header>
    <router-view/>
</div>
 <div class="cookie" v-bind:class="{'cookie_active': cookieActive}">
   <h3 class="cookieText" v-on:click="closeCookie">Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов <router-link to="/CookieAgreement">cookie</router-link></h3>
   <div class="cookieBTN" v-on:click="closeCookie">x</div>
   </div>
    <FooterComponent></FooterComponent>
    </div>
</template>

<style>

@font-face {
   font-family: RoadRadioThin; /* Имя шрифта */
   src: url(./assets/fonts/RoadRadio-Thin.otf); /* Путь к файлу со шрифтом */
  }
  @font-face {
     font-family: RoadRadio; /* Имя шрифта */
     src: url(./assets/fonts/RoadRadio.otf); /* Путь к файлу со шрифтом */
    }

  @font-face {
     font-family: RoadRadioBlack; /* Имя шрифта */
     src: url(./assets/fonts/RoadRadio-Black.otf); /* Путь к файлу со шрифтом */
    }

  @font-face {
    font-family: RoadRadioBold; /* Имя шрифта */
    src: url(./assets/fonts/RoadRadio-Bold.otf); /* Путь к файлу со шрифтом */
    }

  @font-face {
    font-family: RoadRadioLight; /* Имя шрифта */
    src: url(./assets/fonts/RoadRadio-Light.otf); /* Путь к файлу со шрифтом */
    }

*{
  /* background: url('./assets/back-image.svg'); */
  /* background-size: contain; */
  /* background-position: left; */
  font-family: Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
html{
  background: url('./assets/back-image.svg');
  background-size: cover;
}

body{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app_container{
  min-height: calc(100vh - 110px);
}

#app {
  /* font-family: RoadRadioLight, Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  /* color: #2c3e50; */
  color: white;
  width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  /* background: url('./assets/background-image.png'); */
  }
*{margin: 0;}
.nav {
  /* margin-left: 5em; */
  /* margin: 0 auto; */
  display: flex;
  align-items: center;
  /* border: solid 3px green; */
  /* width: 775px; */
  font-size: 20px;
  /* color: #7698CF; */
  color: #dacfeb;
  background: none;
  width: 100%;
  white-space: pre;
  height: 150px;
}
/* .tel{
  color: #FF8240;
align-self: flex-end;
  } */

.nav a {
  font-weight: bold;
  /* color: #B4D024; */
  color: #dacfeb;
  margin-right: 30px;
  text-decoration: none;
}

.nav a.router-link-exact-active {
  color: #F1D358;
  opacity: 0.95;
  /* 42b983 */
  /* margin: 0 auto; */
}
.nav a:hover{
  color: #d6cb9d;
}
header{
  /* width: 99%; */
  display: flex;
  align-items: center;
  justify-content: start;
  /* width: 1170px; */
  margin-top: 1em;
  padding-top: 10px;
  /* border: solid 3px yellow; */
  }

 #logotype{
   image-rendering: optimizeQuality;
   width: auto;
   height: auto;
   flex: 0 0 12em;
   /* margin-left: 19.5em; */
   padding-right: 5em;
   /* border-right: 6px solid #BFA730; */
    -moz-user-select: none;

    -webkit-user-select: none;

    -ms-user-select: none;

    -o-user-select: none;

    user-select: none;
    pointer-events: none;
    background: none;
  }

.emergency{
    flex-direction: column;
    margin-left: auto;
    position: relative;
    right: 0;
    padding: 0;
    }

.emergency_service_info{
margin-left: 3em;
left: 0;
}

  .pass{
flex: 2;
      }

.hat{
  margin: 0 auto;
  background: none;
}

.nav #tel{
  color: #F1D358;
  /* margin-left: auto; */
  align-self: flex-end;
  font-weight: bold;
  left: 0;
  opacity: 0.95;
  }

  .cookie{
  background: #ad9e61;
  position: fixed;
  bottom:0;
  width: 1200px;
  height: 150px;
  transition: all 0.8s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  }

.cookieText{
  margin: 0 auto;
  margin-top: 55px;
}

.cookieBTN{
  position: absolute;
  right: 10px;
  top: 10px;
  background: grey;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
  }

  .cookieBTN:hover{
    background: rgb(58, 53, 53);
  }

  .cookie_active{
  background: #8f855d;
  position: fixed;
  bottom:0;
  width: 1200px;
  height: 120px;
  transition: all 0.8s ease 0s;
  opacity: 1;
  visibility:visible;
  border-radius: 10px 10px 0 0;
  }

  .burger{
    display: none;
  }

  @media (max-width: 1280px) {
  #app {
    width: 900px;
    }
   .cookie{
     width: 900px;
   }
   .nav{
     margin-left: 50px;
     justify-content: space-between;
     }
   header{width: 900px;
   justify-content:space-between;}
   #logotype{width: 220px;}
   .nav a{
     margin-right: 5px;
     font-size: 0.9em;
     }
     .emergency{
       font-size: 0.9em;
       /* text-align: start; */
     }
     .logotype_container{
       width: 220px;
       }
     .nav a{
       margin-left: 1.2em;
       }
       .nav #tel{
margin-right: 54px;
       }

.emergency_service_info{}

     }

     @media (max-width: 980px) {
       #app {
    width: 700px;
    }
   .cookie{
     width: 700px;
   }
   .nav{
     margin-left: 40px;
     justify-content: space-between;
     }
   header{width: 700px;
   justify-content:space-between;}
   #logotype{width: 180px;}
   .nav a{
     margin-right: 5px;
     font-size: 0.7em;
     }
     .emergency{
       font-size: 0.7em;
       /* text-align: start; */
     }
     .logotype_container{
       width: 180px;
       }
     .nav a{
       margin-left: 1em;
       }
       .nav #tel{
margin-right: 48px;
       }

.emergency_service_info{}
     }

     @media (max-width: 767px) {
       #app {
    width: 550px;
    }
   .cookie{
     width: 400px;
     margin: 0 auto;
     height: 110px;
   }
   .nav{
      /* display: flex; Включить при нажатой кнопке! */
     display: none;
     flex-direction: column;
     /* margin-left: 10px; */
     justify-content: space-between;
     margin-top: 2em;
     margin-bottom: 2em;
     }

     .nav_active{
    display: flex;
    opacity: 1;
    flex-direction: column;
     /* margin-left: 10px; */
     justify-content: space-between;
     margin-top: 2em;
     margin-bottom: 2em;
     margin-right: 36%;
     }

    .burger{
  display: flex; /* используем flex для центрирования содержимого */
  align-items: center;  /* центрируем содержимое кнопки */
  position:absolute;
  /* top: 80px;
  left: 190px; */
  right: 2.5em;
  margin-top: 110px;
   width: 26px;
  height: 26px;
  cursor: pointer;
    }

  .burger > span,
  .burger > span::before,
  .burger > span::after{
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ada6a6;
  }

  .burger > span::before{
  content: '';
  top: -8px;
  }

   .burger > span::after {
  content: '';
  top: 8px;
}

.burgerToggle > span {
  transform: rotate(45deg);
}

.burgerToggle > span::before {
  top: 0;
  transform: rotate(0);
}

.burgerToggle > span::after {
  top: 0;
  transform: rotate(90deg);
}
.emergency_service_info{
  /* font-size: 1.5em; */
}
   header{width: 550px;
   justify-content:space-between;}
   #logotype{width: 270px;
   }
   .nav{
   }
      .nav a{
     margin-right: 5px;
     font-size: 0.8em;
     margin-left: 1em;
     }
     .emergency{
       font-size: 1em;
       margin-left: 67%;

       /* text-align: start; */
     }
     .logotype_container{
       width: 270px;
      margin-right: 150px;
       }

       .nav #tel{
      margin-right: 48px;
       }
       .hat{
      display: flex;
      flex-direction: column;
      margin-top: 10px;
       }

.emergency{
  left: -210px;
}

.nav #tel{
margin-right: -25px;
margin-top: 20px;
font-size: 1.2em;
       }

.cookieText{
  margin: 0 auto;
  margin-top: 45px;
  font-size: 0.8em;
}

.emergency_service_info{}

#app{width: 400px;}
     }

 @media (max-width: 550px) {
   #app {
    width: 400px;
    }
    body{width: 400px;}
   .cookie{
     width: 400px;
     margin: 0 auto;
   }
   #logotype{width: 280px;
   margin-left: 30%;
   }
.hat{width: 400px;
margin: 0 auto;
margin-left: 4em;
}
 .burger{right: -4em;}
 .nav{font-family: Arial, sans-serif;
 font-size: 30px;
 line-height: 1.5em;
 margin-left: 36%;
 }
 nav a{
/* margin-left: 100px; */
 }
 .emergency{
   margin-left: 24px;
   line-height: 1.5em;
   margin-top: 10px;
   margin: 0 auto;
   left: -25px;
   font-family: Arial, sans-serif;
   font-size: 22px;
   font-weight: bold;
 }
 #tel{
   margin-left: 42px;
 }
 }
@media (max-width: 500px){
#app{
  margin-left: -7%;
}
}
 @media (max-width: 420px){
  #app{width: 370px;
  margin-left: -1px;}
   .app_container{width: 330px;
   margin-left: -5px;
   margin: 5px;}
.logotype_container{
  width: 340px;
  margin-left: -28px;
}
#logotype{width: 250px;}
.hat{
  width: 350px;
  margin-left: 0px;
}
.burger{
  right: 0px;
  top: 6px;
}
.app_container a{
  width: 350px;
}
.cookie{
  width: 320px;
  margin: 0 auto;
}
.nav{
  font-size: 1em;
  margin-left: 52%;
}
.nav a{
  width: 70%;
  margin-top: 20px;
  font-size: 1.2em;
}
.emergency{
  font-size:1em;
  margin-top: 20px;
  }
  #tel{
    margin-left: 22px;
  }
 }
 </style>

<script>
import FooterComponent from './views/FooterComponent.vue'

export default {
  components: { FooterComponent },
  props: ['id', 'name'],
  data: function () {
    return {
      cookieActive: false,
      burgerToggle: false,
      navActive: false
    }
  },
  mounted () {
    document.title = 'УК "Городской СервисЦентр"'
    this.callFunc()
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    callFunc: function () {
      const v = this
      setTimeout(function () {
        v.cookieActive = true
      }, 4000)
    },
    closeCookie: function (event) {
      this.cookieActive = false
    },
    changeBtn: function (event) {
      if (this.burgerToggle === false) {
        this.burgerToggle = true
        this.navActive = true
      } else {
        this.burgerToggle = false
        this.navActive = false
      }
    }

  },
  head: {
    link: [
      {
        rel: 'icon',
        href: require('./assets/logo.png')
      }
    ]
  }
}
</script>
