<template>
  <div class="Footer">
      <div class="container">
          <div class="content_container">
              <div class="location">
                    <div class="adress">
                        <p class="h5">Адрес:</p>
                         <p>394030 г. Воронеж, ул. Плехановская, д. 66б, оф. 205</p>
                    </div>
                         <div class="mail">
                             <p class="h5">электронная почта: &nbsp;</p>
                            <p class="mail_text"><a class="mail_text" href="mailto:ukgsc@mail.ru">ukgsc@mail.ru</a></p>
                        </div>
                        <div class="work_time">
                            <p class="h5">Режим работы:</p>
                            <p>ПН-ЧТ: 08.00-17.00; ПТ 08.00-16.00; перерыв: 12.00-13.00</p>
                        </div>
              </div>
              <div class="requisites">
                <p id="name">Общество с ограниченной ответственностью <br> "Управляющая компания "Городской СервисЦентр"</p>
                <div class="inn">
                <p class="h5">ИНН:</p>
                <p>3665086050</p>
                </div>
                <div class="ogrn">
                    <p class="h5">ОГРН:</p>
                    <p>1113668050704</p>
                </div>
                <div class="director">
                    <p class="h5">ДИРЕКТОР:</p>
                    <p>Плетнёв Сергей Васильевич</p>
                </div>
                <div class="mycontact">
                    <a href="mailto:zhilin-work@mail.ru" class="linkToMe">разработка сайта: zhilin-work@mail.ru</a>
                </div>
              </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    /* position: absolute; */
    width: 100%;
    height: 140px;
    background:#7870837e;
    border-radius: 10px 10px 0 0;
    font-size: 0.8em;
    color:rgb(250, 244, 208);
    letter-spacing: 0.5px;
    line-height: 1.2;
    margin-top: 20px;
}

.content_container{
    display: flex;
    }

.requisites{
    flex: 100px;
    flex-grow: 1;
    text-align: start;
    padding: 5px 0 5px 10px;
    margin-right: 50px;
    margin-top: 5px;
}

.location{
    flex-grow: 1.1;
    text-align: start;
    padding: 5px 0 5px 10px;
    margin-left: 5px;
    margin-top: 5px;
}
.inn{
    display: flex;
}
.ogrn{
    display: flex;
}
.director{
    display: flex;
    margin-top: 10px;
}
.h5{
    color:rgb(219, 219, 219)
}
.adress{}

#name{
    margin-bottom: 10px;
}

.mail{
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
}

.mail_text{
    color:rgb(255, 255, 255);
    font-size: 1em;
    margin-left: 3px;
}

.linkToMe{
    color:rgb(250, 244, 208);
    display: flex;
    margin-top: 5px;
}

@media (max-width: 1280px){
.container{
font-size: 0.7em;
}
.location{
    margin-left: 4em;
}
}

@media (max-width: 980px){
.container{
font-size: 0.55em;
}
.location{
    margin-left: 4em;
}

@media (max-width: 767px){
.container{height: 230px;
width: 400px;
/* margin-left: 30px; */
}

.content_container{
    display: flex;
    flex-direction: column;
    font-size: 1.3em;
    }
.requisites{
margin-left: 46px;
}
.location{
    /* margin-left: 92px; */
}
}
}
@media (max-width: 550px){
.container{width: 400px;
height: 300px;
margin-left: 5rem;
/* display: none; */
}
}
@media (max-width: 420px){
    .container{
        margin-left: 17px;
        width: 380px;
        height: max-content;
    }
    .requisites{
        margin-left: 3%;
    }
    .location{
        margin-left: 3%;
    }
}
</style>
