import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    abc: 'asdggg',
    houses3: []

  },
  mutations: {
  },
  actions: {
    async loadHouses () {
      const response = await fetch('http://127.0.0.1:8000/api/houses')
      const result = await response.json()
      this.houses3 = result
      // console.log(this.houses3)
    }
  },
  modules: {
  }
})
